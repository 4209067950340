// Intro

.intro
  display: flex
  flex-flow: column nowrap
  justify-content: center
  width: 100%
  height: auto
  padding: 23vh 0 2vh
  min-height: 100vh
  background: $intro url(../../assets/img/intro-001.jpg) center top no-repeat
  background-size: cover
  +v-xs
    padding-top: 20vh
  &__title
    padding: 0
    color: $white
    font-size: 5.25em
    font-weight: 100
    line-height: 1.25
    +v-md
      font-size: 4em
    +v-sm
      margin-top: 20px
      font-size: 2.5em
      line-height: 1.05
    +v-xs
      width: 80%
      margin: 20px auto
      font-size: 1.95em
      line-height: 1.25
  &__subtitle
    padding: 0
    color: $white
    font-size: 1.75em
    font-weight: 300
    +v-sm
      font-size: 1.2em
      line-height: 1.25
    +v-xs
      width: 80%
      margin: 20px auto
      line-height: 1.5
  &__text
    margin-bottom: 56px
    padding: 0
    color: $white
    font-size: 1.5em
    font-weight: 300
    font-style: italic
    +v-sm
      font-size: 1.15em
    +v-xs
      margin-bottom: 25px
  &__smile
    display: inline-block
    position: relative
    top: 2px
    left: 4px
    font-style: normal
    animation: Smile infinite 2s
    animation: Smile infinite 2s

@keyframes Smile
  from
    transform: rotate(-360deg)
  to
    transform: rotate(0)
