// Reviews

#reviews_block
  padding-bottom: 80px
  background-color: $white

.review
  blockquote
    padding: 0
  &__link
    margin: 60px auto
    text-align: center
    +v-sm
      margin: 0 auto
    +v-xs
      .btn
        width: 80%
        margin: 0 auto -7%
  &__block
    display: block
    width: 50%
    padding: 5%
    +v-md
      padding: 4% 2%
    +v-sm
      width: 100%
      padding: 0
  &__right,
  &__left
    display: flex
    flex-flow: row wrap
    justify-content: space-between
  &__right
    .review
      &__block
        order: 2
      &__image
        order: 1
  &__left
    order: 1
    .review
      &__block
        order: 1
        +v-sm
          order: 2
      &__image
        order: 2
        +v-sm
          order: 1
  &__image
    display: block
    height: 420px
    width: 40%
    margin: 5%
    border-radius: 8px !important
    object-fit: cover
    +v-sm
      width: 100%
      height: 200px
      margin: -2% 5% -2%
    +v-xs
      margin-top: -5%
  &__name
    padding: 10% 7.5% 0
    color: $premium
    font-size: 2em
    font-weight: 500
    +v-sm
      padding: 5% 7.5% 0
    +v-xs
      padding: 10% 12% 0
      font-size: 1.75em
  &__position
    display: block
    margin: -8px 0 40px
    color: $lite
    font-size: 1.1em
    font-family: $play
    font-style: italic
  &__text
    margin: 24px auto 40px
    color: $medium
    font-size: 1.25em
    font-family: $play
    font-style: italic
    line-height: 2
    +v-sm
      margin: -15px auto 40px
    +v-xs
      font-size: 1.05em