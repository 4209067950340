// Contact

.thankyou-main-text
  font-size: 3em !important
  font-family: $basic !important
  color: $premium !important

#JotFormIFrame-211813077678058
  width: 100%
  height: 130vh
  transform: translateY(-100px)
  margin-bottom: -155px
  +v-sm
    margin-top: 40px
.form-all
  background-image: none !important
  box-shadow: none !important
.form-input-wide
  +v-xs
    display: block
  .form-sub-label-container
    +v-xs
      display: block
.contact
  width: 50%
  margin: 0 auto 80px
  &__input,
  &__textarea,
  &__submit
    display: flex
    align-items: center
    width: 100%
    margin: 24px 0
    padding: 0 24px 0 60px
    height: 64px
    color: $white
    font-size: 1em
    font-weight: 400
    font-family: $basic
    border-radius: 8px
    background-color: $premium
    border: none
    outline: none
    +placeholder
      color: $white
      opacity: 1
      transition: all ease-out .3s
    &:focus
      +placeholder
        opacity: 0
  &__input
    &--mail
      background: $premium url(../../assets/img/icons/contact/mail.svg) 20px center no-repeat
    &--user
      background: $premium url(../../assets/img/icons/contact/user.svg) 20px center no-repeat
  &__textarea
    align-items: flex-start
    height: auto
    height: 200px
    padding: 24px
  &__submit
    justify-content: center
    color: $white
    font-size: 1em
    letter-spacing: .075em
    text-transform: uppercase
    background-color: $dark
    transition: all ease-out .3s
    &:hover
      background-color: $premium
      cursor: pointer
